import React from 'react';
import { ChakraProvider, Box, extendTheme } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import Pricing from './components/Pricing';
import Example from './components/Example';
import Ticker from './components/Ticker';
import UseCases from './components/UseCases';
import FAQ from './components/FAQ';

const theme = extendTheme({
  fonts: {
    heading: 'Rubik, sans-serif',
    body: 'Rubik, sans-serif',
  }
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Navbar />
        <Hero />
        <Ticker/>
        <UseCases/>
        <Example />
        <Features />
        <Pricing />
        <FAQ/>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;