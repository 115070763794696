import React from 'react';
import { Box, Heading, Text, Container, Button, Circle } from '@chakra-ui/react';

const Example = () => {
  const handleDownload = () => {
    // Fetch the CSV file
    fetch('./mapsexample.csv')
      .then(response => response.text())
      .then(csvData => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'mapsexample.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <Box bg="blue.400" py={{ base: 150, md: 300 }} id="example">
      <Container maxW="container.xl">
        <Heading
          as="h1"
          fontSize={{ base: '40px', md: '60px' }}
          mb={{ base: 4, md: 8 }}
          textAlign="center"
          color="white"
        >
          Here's What It Looks Like
        </Heading>
        <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="center" color="white" mb={{ base: 8, md: 12 }}>
          We give you everything you can find on Google Maps including name, address, phone, review count reviews, website, and more.
        </Text>
        <Box bg='gray.200' w='full' h={{ base: '30px', md: '40px' }} borderTopRightRadius={25} borderTopLeftRadius={25} display="flex" alignItems="center" pl={{ base: 2, md: 4 }}>
          <Circle size={{ base: '8px', md: '12px' }} bg="red.400" mr={2} />
          <Circle size={{ base: '8px', md: '12px' }} bg="yellow.400" mr={2} />
          <Circle size={{ base: '8px', md: '12px' }} bg="green.400" />
        </Box>
        <Box w="full" h={{ base: '400px', md: '700px' }} overflow="hidden">
          <iframe
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSuUic4pbW9GOo8ZISqid5wnRrv9lvZO7CqxlQSPnZ7VOYMECYrrjY7i6UtwXU6lyAszQkeXcGjsMPo/pubhtml?widget=true&amp;headers=false"
            width="100%"
            height="100%"
            title='googlemaps'
          ></iframe>
        </Box>
        <Box bg='gray.200' w='full' h={{ base: '10px', md: '20px' }} borderBottomRightRadius={25} borderBottomLeftRadius={25} display="flex" alignItems="center" pl={{ base: 2, md: 4 }} shadow="xl">
        </Box>
        <Box textAlign="center" mt={{ base: 4, md: 8 }}>
          {/* <Button
            bgGradient="linear(to-r, red.500, red.400)"
            color="white"
            _hover={{ bgGradient: "linear(to-r, red.600, red.500)" }}
            onClick={handleDownload}
          >
            Get Our Full Example Data
          </Button> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Example;