import React from 'react';
import { Box, Heading, Text, Center, Image, Stack, useBreakpointValue } from '@chakra-ui/react';
import data from '../assets/xls.png';
import mine from '../assets/mine.png';
import search from '../assets/search.png';

const Features = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const py = isMobile ? '2rem' : '4rem';
  const px = isMobile ? 4 : 0;
  const imageWidth = '300px';
  const showImages = useBreakpointValue({ base: false, md: true });

  return (
    <Box py={py} px={px} id="features" bg="blue.400">
      <Center>
        <Box maxW="1000px">
          <Heading as="h1" fontSize={isMobile ? '40px' : '60px'} textAlign="center" mb="2rem" color="white">
            How Scrape America Works
          </Heading>
          <Stack spacing={10}>
            {/* Step 1 */}
            <Box display="flex" flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
              {showImages && (
                <Box flex="1" mb={{ base: '2rem', md: 0 }}>
                  <Center>
                    <Image src={search} alt="Search Graphic" borderRadius="md" w={imageWidth} />
                  </Center>
                </Box>
              )}
              <Box flex="1" bg="gray.100" p="2rem" borderRadius="25px" textAlign="center" shadow="xl">
                <Heading as="h2" size="xl" mb="1rem">
                  Enter Your Query
                </Heading>
                <Center>
                  <Box h="5px" w="200px" bg="red.500" mb="20px" borderRadius={20}></Box>
                </Center>
                <Text fontSize={isMobile ? '18px' : '20px'}>
                  Start by entering your desired search query upon checkout. Our powerful scraping algorithms will take it from there.
                </Text>
              </Box>
            </Box>

            {/* Step 2 */}
            <Box display="flex" flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
              <Box flex="1" bg="gray.100" p="2rem" borderRadius="25px" textAlign="center" order={{ base: 2, md: 1 }} shadow="xl">
                <Heading as="h2" size="xl" mb="1rem">
                  We'll Get To Work
                </Heading>
                <Center>
                  <Box h="5px" w="200px" bg="red.500" mb="20px" borderRadius={20}></Box>
                </Center>
                <Text fontSize={isMobile ? '18px' : '20px'}>
                  Our advanced scraping technology will generate a comprehensive dataset from the entire country based on your query.
                </Text>
              </Box>
              {showImages && (
                <Box flex="1" mb={{ base: '2rem', md: 0 }} order={{ base: 1, md: 2 }}>
                  <Center>
                    <Image src={mine} alt="Mining Graphic" borderRadius="md" w={imageWidth} />
                  </Center>
                </Box>
              )}
            </Box>

            {/* Step 3 */}
            <Box display="flex" flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
              {showImages && (
                <Box flex="1" mb={{ base: '2rem', md: 0 }}>
                  <Center>
                    <Image src={data} alt="Data Graphic" borderRadius="md" w={imageWidth} />
                  </Center>
                </Box>
              )}
              <Box flex="1" bg="gray.100" p="2rem" borderRadius="25px" textAlign="center" shadow="xl">
                <Heading as="h2" size="xl" mb="1rem">
                  Receive Your Data
                </Heading>
                <Center>
                  <Box h="5px" w="200px" bg="red.500" mb="20px" borderRadius={20}></Box>
                </Center>
                <Text fontSize={isMobile ? '18px' : '20px'}>
                  Once the data generation is complete, you'll receive a massive CSV file containing all the information. Use this valuable data for analysis, research, lead gen, or any other purpose you desire.
                </Text>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Center>
    </Box>
  );
};

export default Features;