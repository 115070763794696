import React, { useState, useRef } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Link,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  List,
  ListItem,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const Pricing = () => {
  const [selectedStates, setSelectedStates] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsDropdownOpen(false),
  });

  const handleStateChange = (selectedState) => {
    if (selectedState && !selectedStates.includes(selectedState)) {
      setSelectedStates([...selectedStates, selectedState]);
      setInputValue("");
      setIsDropdownOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleRemoveState = (state) => {
    setSelectedStates(selectedStates.filter((s) => s !== state));
  };

  const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ];
  

  const filteredStates = stateOptions.filter(
    (state) =>
      state.toLowerCase().includes(inputValue.toLowerCase()) &&
      !selectedStates.includes(state)
  );

  const handleCheckout = async () => {
    console.log(selectedStates);
    try {
      const response = await fetch("/api/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ selectedStates }),
      });

      if (response.status === 200) {
        const { sessionId } = await response.json();
        const stripe = await window.Stripe(
          "pk_live_51Np1XwFJX4EAoYbTcOn4Bbw584wgsTUUByNouLT9nRngQg5gbOehxoCcYNNgq5t5dGHKljcBym5Kq0qWN0brGpNj00ixwDgcbF"
        );
        await stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Failed to create checkout session");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box id="pricing" bg="blue.400" py={200}>
      <Heading as="h1" fontSize="60px" textAlign="center" mb="2rem" color="white">
        Pricing
      </Heading>
      <Flex justify="center" flexWrap="wrap" gap={8} px={2}>
        <Box
          borderRadius="25px"
          overflow="hidden"
          boxShadow="xl"
          maxW="md"
          w="100%"
          p={8}
          textAlign="center"
          bg="white"
        >
          <Heading as="h3" size="xl" mb={4}>
            Scrape the States
          </Heading>
          <Flex align="baseline" justify="center" mb={6}>
            <Text fontSize="4xl" fontWeight="bold" mr={2}>
              ${selectedStates.length > 0 ? selectedStates.length * 49 : 49}
            </Text>
            <Text fontSize="xl" color="gray.500" textDecoration="line-through">
              ${selectedStates.length > 0 ? selectedStates.length * 129 : 129}
            </Text>
          </Flex>
          <Text fontSize="lg" mb={8}>
            Unlock the power of data scraping for selected states.
          </Text>
          <Stack spacing={4} mb={8}>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Scrape data from a specific states</Text>
            </Flex>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Access to amazing bulk data</Text>
            </Flex>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Priority support</Text>
            </Flex>
          </Stack>
          <FormControl mb={4} position="relative">
            <FormLabel>Select States</FormLabel>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type and select states"
              size="md"
            />
            {isDropdownOpen && filteredStates.length > 0 && (
              <Box
                ref={dropdownRef}
                position="absolute"
                top="100%"
                left="0"
                right="0"
                bg="white"
                border="1px solid #e2e8f0"
                borderRadius="md"
                boxShadow="xl"
                zIndex="100"
                maxH="120px"
                overflowY="auto"
              >
                <List>
                  {filteredStates.map((state) => (
                    <ListItem
                      key={state}
                      cursor="pointer"
                      p={2}
                      borderBottom="1px solid #e2e8f0"
                      _hover={{ bg: "gray.100" }}
                      onClick={() => handleStateChange(state)}
                    >
                      {state}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </FormControl>
          <Flex flexWrap="wrap" mb={4}>
            {selectedStates.map((state) => (
              <Tag
                key={state}
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
                mr={2}
              >
                <TagLabel>{state}</TagLabel>
                <TagCloseButton onClick={() => handleRemoveState(state)} />
              </Tag>
            ))}
          </Flex>
          <Text color="gray.400" mb={5}>
            You will enter your keyword at checkout
          </Text>
          <Button
            bgGradient="linear(to-r, red.500, red.400)"
            color="white"
            _hover={{ bgGradient: "linear(to-r, red.600, red.500)" }}
            size="lg"
            isDisabled={selectedStates.length === 0}
            onClick={handleCheckout}
          >
            Get Started
          </Button>
        </Box>
        <Box
          borderRadius="25px"
          overflow="hidden"
          boxShadow="md"
          maxW="md"
          w="100%"
          p={8}
          textAlign="center"
          bg="white"
        >
          <Heading as="h3" size="xl" mb={4}>
            Scrape the Country
          </Heading>
          <Flex align="baseline" justify="center" mb={6}>
            <Text fontSize="4xl" fontWeight="bold" mr={2}>
              $497
            </Text>
            <Text fontSize="xl" color="gray.500" textDecoration="line-through">
              $697
            </Text>
          </Flex>
          <Text fontSize="lg" mb={8}>
            Unlock nationwide data scraping capabilities for your business.
          </Text>
          <Stack spacing={4} mb={8}>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Scrape data from all states</Text>
            </Flex>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Access to advanced features</Text>
            </Flex>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Websites, Ratings, Phone Numbers and More</Text>
            </Flex>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Thousands of great data points on your keyword</Text>
            </Flex>
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
              <Text>Priority support</Text>
            </Flex>
          </Stack>
          <Text color="gray.400" mb={5}>
            You will enter your keyword at checkout
          </Text>
          <Link href="https://buy.stripe.com/bIYbJU9Ud4oC4neaEF" isExternal>
            <Button
              bgGradient="linear(to-r, red.500, red.400)"
              color="white"
              _hover={{ bgGradient: "linear(to-r, red.600, red.500)" }}
              size="lg"
            >
              Get Started
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default Pricing;