import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Stat, StatNumber, StatLabel } from '@chakra-ui/react';

function Ticker() {
  const [scrapedRows, setScrapedRows] = useState(0);
  const [scrapedEmails, setScrapedEmails] = useState(0);
  const [connectedSources, setConnectedSources] = useState(0);

  useEffect(() => {
    const targetScrapedRows = 50000000; // 18M scraped rows
    const targetScrapedEmails = 100; // 10k scraped emails
    const targetConnectedSources = 50; // 3+ connected sources

    const incrementRows = Math.ceil(targetScrapedRows / 50);
    const incrementEmails = Math.ceil(targetScrapedEmails / 50);
    const incrementSources = Math.ceil(targetConnectedSources / 1000);

    const timer = setInterval(() => {
      setScrapedRows((prevRows) => {
        const newRows = prevRows + incrementRows;
        return newRows >= targetScrapedRows ? targetScrapedRows : newRows;
      });

      setScrapedEmails((prevEmails) => {
        const newEmails = prevEmails + incrementEmails;
        return newEmails >= targetScrapedEmails ? targetScrapedEmails : newEmails;
      });

      setConnectedSources((prevSources) => {
        const newSources = prevSources + incrementSources;
        return newSources >= targetConnectedSources ? targetConnectedSources : newSources;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Box px={5} bg="blue.400">
        <SimpleGrid columns={{ base: 1, md: 3 }} maxW="6xl" mx="auto" textAlign="center" color="white" borderRadius={25}>
          <Stat bg="blue.600" color="white" borderTopLeftRadius={{ base: 25, md: 25 }} borderBottomLeftRadius={{ base: 0, md: 25 }} borderTopRightRadius={{ base: 25, md: 0 }} p={8} shadow="xl">
            <StatNumber fontSize="5xl">{scrapedRows.toLocaleString()}+</StatNumber>
            <StatLabel fontSize="2xl">Scraped Businesses</StatLabel>
          </Stat>
          <Stat bg="red.500" color="white" borderRadius={{ base: 0, md: 0 }} p={8} shadow="xl">
            <StatNumber fontSize="5xl">{scrapedEmails.toLocaleString()}+</StatNumber>
            <StatLabel fontSize="2xl">Queries Searched</StatLabel>
          </Stat>
          <Stat bg="white" color="gray.800" borderTopRightRadius={{ base: 0, md: 25 }} borderBottomRightRadius={{ base: 25, md: 25 }} borderBottomLeftRadius={{ base: 25, md: 0 }} p={8} shadow="xl">
            <StatNumber fontSize="5xl">{connectedSources.toLocaleString()}</StatNumber>
            <StatLabel fontSize="2xl">States Scraped</StatLabel>
          </Stat>
        </SimpleGrid>
      </Box>
    </>
  );
}

export default Ticker;