import React from 'react';
import { Grid, GridItem, Heading, Text, Box, useBreakpointValue } from '@chakra-ui/react';

const UseCases = () => {
  const useCases = [
    {
      title: '📊 Market Research',
      description: 'Analyze consumer behavior, trends, and preferences across the nation.',
    },
    {
      title: '🏦 Financial Services',
      description: 'Assess risk, identify opportunities, and optimize lending strategies.',
    },
    {
      title: '🛒 Retail Planning',
      description: 'Optimize product assortment, pricing, and supply chain management.',
    },
    {
      title: '🌐 Targeted Advertising',
      description: 'Reach specific demographics and tailor marketing campaigns.',
    },
    {
      title: '🚗 Automotive Industry',
      description: 'Understand regional preferences and plan vehicle distribution.',
    },
    {
      title: '🏘️ Real Estate',
      description: 'Identify hot markets, forecast trends, and optimize property investments.',
    },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });
  const gridColumns = isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)';
  const px = isMobile ? 2 : 0;

  return (
    <Box bg="blue.400" pt={isMobile ? 100 : 200} id="use-cases">
      <Box maxW="800px" mx="auto" px={px}>
        <Heading mb={6} textAlign="center" color="white" as="h1" fontSize={isMobile ? '40px' : '60px'}>
          Use Cases
        </Heading>
        <Grid templateColumns={gridColumns} gap={6}>
          {useCases.map((useCase, index) => (
            <GridItem key={index} p={6} borderRadius="25px" bg="gray.100" shadow="xl">
              <Heading size="lg" mb={2}>
                {useCase.title}
              </Heading>
              <Box h="5px" w="200px" bg="red.500" mb="20px" borderRadius={20}></Box>
              <Text fontSize="20px">{useCase.description}</Text>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default UseCases;