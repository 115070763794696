import React from 'react';
import { Box, VStack, Heading, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useBreakpointValue, Center } from '@chakra-ui/react';

const FAQ = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const px = isMobile ? 4 : 8;

  return (
    <Box bg="blue.400" py={16} id="faq">
      <VStack spacing={12} alignItems="center" px={px}>
        <Heading as="h2" size="3xl" color="white" textAlign="center">
          FAQ
        </Heading>
        <Box maxW="800px" w="100%" bg="white" borderRadius="25px" py={10} boxShadow="xl">
          <Accordion allowMultiple>
            <AccordionItem border="none">
              <AccordionButton py={6}>
                <Box flex="1" textAlign="left">
                  <Text fontSize={isMobile ? "xl" : "2xl"} fontWeight="bold">Is this legal?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={8}>
                <Text fontSize={isMobile ? "lg" : "xl"}>
                  Yes. Scraping is legal but not all ways of scraping are legal. We ensure the highest level of protection only sraping data that is publically accessible. Think of it as yellowpages at scale!
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <Center>
              <Box w="90%" h="4px" bg="red.500" borderRadius={25}></Box>
            </Center>

            <AccordionItem border="none">
              <AccordionButton py={6}>
                <Box flex="1" textAlign="left">
                  <Text fontSize={isMobile ? "xl" : "2xl"} fontWeight="bold">How does Scrape America work?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={8}>
                <Text fontSize={isMobile ? "lg" : "xl"}>
                  Put in your query and let our scrapers do the work! We will gather all the data across the country for you to use for any purpose.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <Center>
              <Box w="90%" h="4px" bg="red.500" borderRadius={25}></Box>
            </Center>

            <AccordionItem border="none">
              <AccordionButton py={6}>
                <Box flex="1" textAlign="left">
                  <Text fontSize={isMobile ? "xl" : "2xl"} fontWeight="bold">What can I do with this information?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={8}>
                <Text fontSize={isMobile ? "lg" : "xl"}>
                  The possibilities are really endless. From lead generation to market research. You are free to use this data for whatever your needs are.
                   </Text>
              </AccordionPanel>
            </AccordionItem>
            <Center>
              <Box w="90%" h="4px" bg="red.500" borderRadius={25}></Box>
            </Center>
          </Accordion>
        </Box>
      </VStack>
    </Box>
  );
};

export default FAQ;