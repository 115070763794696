import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Stack,
  Link as ChakraLink,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { Link } from 'react-scroll';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from '../assets/logo.png';

const NavLink = ({ to, children, onClick }) => (
  <Link to={to} smooth={true} duration={500} offset={-120} onClick={onClick}>
    <ChakraLink
      position="relative"
      _hover={{
        textDecoration: 'none',
        _after: { transform: 'scaleX(1)' },
      }}
      _after={{
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '2px',
        bottom: '-4px',
        left: 0,
        backgroundColor: 'currentColor',
        borderRadius: 'full',
        transform: 'scaleX(0)',
        transition: 'transform 0.3s ease-in-out',
      }}
      _focus={{
        outline: 'none',
        _after: { transform: 'scaleX(1)' },
      }}
    >
      {children}
    </ChakraLink>
  </Link>
);

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      as="nav"
      position="sticky"
      top={0}
      zIndex={999}
      bg={isScrolled ? 'blue.400' : 'white'}
      color={isScrolled ? 'white' : 'gray.800'}
      px={{ base: 4, md: 8, lg: 16 }}
      py={{ base: 6, md: 8, lg: 10 }}
      transition="all 0.3s ease-in-out"
    >
      <Flex align="center" justify="space-between" wrap="wrap">
        <Flex align="center">
          <Image src={logo} alt="Logo" boxSize="50px" mr={4} />
          <Heading as="h1" size="xl" letterSpacing={'-.1rem'}>
            Scrape America
          </Heading>
        </Flex>
        <Spacer />
        <Box display={{ base: 'flex', md: 'none' }}>
          <IconButton
            icon={isOpen ? <CloseIcon boxSize={6} /> : <HamburgerIcon boxSize={6} />}
            aria-label="Open Menu"
            onClick={onOpen}
            variant="ghost"
            _hover={{ bg: isScrolled ? 'blue.500' : 'gray.100' }}
            color={isScrolled ? 'white' : 'gray.800'}
          />
        </Box>
        <Box display={{ base: 'none', md: 'flex' }}>
          <Stack direction="row" spacing={8} fontSize="xl">
            <NavLink to="hero">Home</NavLink>
            <NavLink to="use-cases">Use Cases</NavLink>
            <NavLink to="features">How It Works</NavLink>
            <NavLink to="example">Example</NavLink>
            <NavLink to="pricing">Pricing</NavLink>
            <NavLink to="faq">FAQ</NavLink>
          </Stack>
        </Box>
      </Flex>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="blue.400" color="white">
          <DrawerBody>
            <Stack spacing={8} fontSize="xl" fontWeight="bold" mt={8}>
              <NavLink to="hero" onClick={onClose}>Home</NavLink>
              <NavLink to="use-cases" onClick={onClose}>Use Cases</NavLink>
              <NavLink to="features" onClick={onClose}>How It Works</NavLink>
              <NavLink to="example" onClick={onClose}>Example</NavLink>
              <NavLink to="pricing" onClick={onClose}>Pricing</NavLink>
              <NavLink to="faq" onClick={onClose}>FAQ</NavLink>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;