import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Container,
  Image,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-scroll";
import heroImage from "../assets/am.png";

const Hero = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      id="hero"
      py={{ base: "3rem", md: "6rem" }}
      bgGradient="linear(to-b, white, white, blue.400)"
    >
      <Container maxW="container.lg">
        <Flex
          align="center"
          direction={{ base: "column", md: "row" }}
          minHeight={{ base: "auto", md: "60vh" }}
        >
          <VStack
            flex={1}
            spacing={isMobile ? 4 : 6}
            alignItems={{ base: "center", md: "flex-start" }}
            textAlign={{ base: "center", md: "left" }}
            mb={{ base: 8, md: 0 }}
          >
            <Heading
              as="h1"
              fontSize={isMobile ? "50px" : "60px"}
              lineHeight={isMobile ? "1.2" : "1.4"}
            >
              The Simplest Way To Get Data On The Entire Country
            </Heading>
            <Text fontSize={isMobile ? "lg" : "xl"}>
              With a simple search query, we'll scrape the entire country to get
              records on every business in America registered on Google.
            </Text>
            <Link to="pricing" smooth={true} duration={500} offset={-120}>
              <Button
                size={isMobile ? "md" : "lg"}
                bgGradient="linear(to-r, red.500, red.400)"
                color="white"
                _hover={{ bgGradient: "linear(to-r, red.600, red.500)" }}
              >
                Get Started
              </Button>
            </Link>
          </VStack>
          <Box flex={1} display={{ base: "none", md: "block" }}>
            <Image src={heroImage} alt="Hero Image" objectFit="cover" />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Hero;