import React from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  Link,
  IconButton,
  VStack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import logo from '../assets/logo.png';

const Footer = () => {
  return (
    <Box as="footer" bg="blue.400" color="white" py={{ base: '2rem', md: '4rem' }}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'center', md: 'flex-start' }}
        justify="space-between"
        maxW="6xl"
        mx="auto"
        px={{ base: 4, md: 8 }}
      >
        <VStack align={{ base: 'center', md: 'flex-start' }} spacing={4} mb={{ base: 8, md: 0 }}>
          <Flex align="center">
            <Image src={logo} alt="Logo" boxSize={{ base: '40px', md: '50px' }} mr={4} />
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">
              Scrape America
            </Text>
          </Flex>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: 8 }} align="center">
            <Link href="#" _hover={{ textDecoration: 'underline' }}>
              Home
            </Link>
            <Link href="#" _hover={{ textDecoration: 'underline' }}>
              Features
            </Link>
            <Link href="#" _hover={{ textDecoration: 'underline' }}>
              Pricing
            </Link>
            <Link href="#" _hover={{ textDecoration: 'underline' }}>
              Example
            </Link>
          </Stack>
        </VStack>
        <Divider my={8} display={{ base: 'block', md: 'none' }} />
        <VStack align={{ base: 'center', md: 'flex-start' }} spacing={8}>
          <VStack align={{ base: 'center', md: 'flex-start' }} spacing={4}>
            <Text fontWeight="bold">Follow Nate:</Text>
            <HStack spacing={4}>
              <IconButton
                as={Link}
                href="https://www.instagram.com/natecreateshq"
                aria-label="Instagram"
                icon={<FaInstagram />}
                rounded="full"
                bg="white"
                color="blue.400"
                _hover={{ bg: 'gray.100' }}
              />
            </HStack>
          </VStack>
          <VStack align={{ base: 'center', md: 'flex-start' }} spacing={4}>
            <Text fontWeight="bold">Follow Mike:</Text>
            <HStack spacing={4}>
            <IconButton
                as={Link}
                href="https://www.instagram.com/itsmikepowers"
                aria-label="Instagram"
                icon={<FaInstagram />}
                rounded="full"
                bg="white"
                color="blue.400"
                _hover={{ bg: 'gray.100' }}
              />
              <IconButton
                as={Link}
                href="https://www.youtube.com/@itsmikepowers"
                aria-label="YouTube"
                icon={<FaYoutube />}
                rounded="full"
                bg="white"
                color="blue.400"
                _hover={{ bg: 'gray.100' }}
              />
            </HStack>
          </VStack>
        </VStack>
      </Flex>
      <Box mt="2rem" textAlign="center">
        <Text fontSize={{ base: 'xs', md: 'sm' }}>
          &copy; {new Date().getFullYear()} Scrape America. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;